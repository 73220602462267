import React from 'react';
import {
   Avatar,
   Card,
   CardActions,
   CardContent, Grid,
   IconButton,
   Link,
   makeStyles, Tooltip,
   Typography
} from '@material-ui/core';

import YouTubeIcon from '@material-ui/icons/YouTube';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles({
   bio: {
      marginTop:20,
      minWidth: 200
   },
   title: {
      fontSize: 14,
      cursor:'pointer'
   },
   tagline: {
      marginBottom: 12,
   },
   mobile:{
      width:'100% !important',
      minWidth:'100% !important'
   }
});
const BioBox = () => {
   const classes = useStyles();
   return(
<Card className={classes.bio}>
   <CardContent>
      <Tooltip title={'*Not including updates'}>
      <Typography className={classes.title} gutterBottom>
         A One-hour* Project Created by:
      </Typography>
      </Tooltip>
      <Avatar style={{float:'right'}} alt="Austin Cameron" src={process.env.PUBLIC_URL + '/Austin_Cameron.jpg'} />

      <Typography variant="h5" component="h2">
         Austin Cameron
      </Typography>
      <Typography className={classes.tagline}>
         @ustinCameron
      </Typography>
   </CardContent>

   <CardActions disableSpacing>
      <Grid container justifyContent={'center'}>
      <Link href="https://Youtube.com/AustinCameron?sub_confirmation=1" target="_blank" rel="noreferrer">
         <IconButton aria-label="Youtube">
            <YouTubeIcon />
         </IconButton>
      </Link>
      <Link href="https://instagram.com/ustinCameron" target="_blank" rel="noreferrer">
         <IconButton aria-label="Instagram">
            <InstagramIcon />
         </IconButton>
      </Link>
      <Link href="https://AustinCameron.com" target="_blank" rel="noreferrer">
         <IconButton aria-label="Website">
            <LanguageIcon />
         </IconButton>
      </Link>
      <Link href="https://twitter.com/ustinCameron" target="_blank" rel="noreferrer">
         <IconButton aria-label="Twitter">
            <TwitterIcon />
         </IconButton>
      </Link>
      <Link href="https://github.com/ustinCameron" target="_blank" rel="noreferrer">
         <IconButton aria-label="Github">
            <GitHubIcon />
         </IconButton>
      </Link>
      <Link href="https://linkedin.com/in/austincameron" target="_blank" rel="noreferrer">
         <IconButton aria-label="LinkedIn">
            <LinkedInIcon />
         </IconButton>
      </Link>
      </Grid>
   </CardActions>
</Card>
)
};

export default BioBox;
