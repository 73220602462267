import React, {useRef, useEffect, useState, useMemo, useContext} from 'react';
import {Button, Grid, useMediaQuery, useTheme} from "@material-ui/core";
import {Save} from "@material-ui/icons";
import {ThemeContext} from "../../lib/contexts";
import {isMobile, toHashtag} from "../../lib/constants/helpers";

const GenerateImage = ({ question, name, answer, imageUrl }) => {
   const { darkMode } = useContext(ThemeContext);
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   const canvasRef = useRef(null);
   const [imageLoaded, setImageLoaded] = useState(false)
   const imageTitle = useMemo(()=>"AI " + name + "'s answer to " + question+" #PetAidIO "+toHashtag(name)+" ", [name, question])
   
   useEffect(() => {
      // Get a reference to the canvas and its context
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
   
   
      // set background color
      ctx.fillStyle= darkMode ? '#444' : '#CECECEFF';
      ctx.fillRect(0,0,canvas.width,canvas.height);
      
      // Load the image and draw it on the canvas
      const image = new Image();
      image.crossOrigin = "anonymous";
      image.src = imageUrl;
      image.onload = () => {
         ctx.drawImage(image, 40, 250, 200, 200);
         ctx.strokeStyle = darkMode ? '#444' : '#CECECEFF';
         ctx.lineWidth = 20;
         ctx.roundRect(40, 250, 200, 200, {upperLeft:20,upperRight:20,lowerLeft:20,lowerRight:20}, false, true);
         setImageLoaded(true)
      };
      
      // Set the font and text color for the text
      ctx.font = '48px sans-serif';
      ctx.fillStyle = darkMode ? '#fff' : '#222';
      ctx.strokeStyle = '#000000';
      ctx.lineWidth = 1;
      
      // question
      ctx.textAlign = "center";
      wrapText(ctx, '"'+question+'"', (canvas.width)/2, 120, (canvas.width - 60), 45)
      
      // name
      ctx.textAlign = "left";
      ctx.fillText("AI "+name+":", 270, 360)
      
      // answer
      // determine how many words
      const wordCount = answer.split(' ').length
      console.log('wordCount')
      console.log(wordCount)
      ctx.fillStyle = darkMode ? '#f15f71' : '#2972e0';
      let lineHeight = 100;
      if(wordCount > 0){
         ctx.font = '80px sans-serif';
      }
      if(wordCount > 15) {
         ctx.font = '64px sans-serif';
         lineHeight = 84;
      }
      if(wordCount > 30) {
         ctx.font = '53px sans-serif';
         lineHeight = 75;
      }
      if(wordCount > 40) {
         ctx.font = '45px sans-serif';
         lineHeight = 60;
      }
      if(wordCount > 50) {
         ctx.font = '35px sans-serif';
         lineHeight = 50;
      }
      //ctx.fillText('"'+answer+'"', 40, 170);
      wrapText(ctx, answer, 40, 550, (canvas.width - 60), lineHeight)
      
   
      // footer
      ctx.font = '45px sans-serif';
      ctx.fillStyle = darkMode ? '#fff' : '#222';
      ctx.textAlign = "center";
      ctx.fillText("PetAid.ai", (canvas.width)/2, (canvas.height) - 40)
      
      
      // dev Draw the border around the image
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#222';
      ctx.strokeRect(0, 0, canvas.width, canvas.height);
   
      // Generate a data URL representing the canvas as an image
      const imageDataUrl = canvas.toDataURL();
      
      // Return the image element
      return <img src={imageDataUrl} alt={imageTitle} />;
   }, [question, name, answer, imageUrl, darkMode]);
   
   if(!question || !name || !answer || !imageUrl ){
      return false
   }
   
   const downloadImage = () => {
      const image = canvasRef.current.toDataURL("image/png");
      if(isMobile()){
         shareOnMobile(
            {
               text: imageTitle,
               url: "https://PetAid.ai",
               title: imageTitle,
               image: image
            },
            (message) => alert(message)
         )
      }else{
         // on desktop
         let tmpLink = document.createElement( 'a' );
         tmpLink.download = imageTitle+'.png';
         tmpLink.href = image;
         document.body.appendChild( tmpLink );
         tmpLink.click();
         document.body.removeChild( tmpLink );
      }
   }
   
   return (<>
      <canvas ref={canvasRef} width={1080} height={1080} style={{opacity:0, left:-1000, top:-1000, position:'fixed'}}/>
      <Grid container>
         <Grid item xs={12}>
            {canvasRef && canvasRef.current && imageLoaded && <img
               style={{margin:'auto',display:'block', width: matchSM ? 280 : 540, marginBottom: matchSM ? 20 : 0}}
               src={canvasRef.current.toDataURL("image/png")}
               alt={imageTitle}/>}
         </Grid>
         <Grid item xs={12}>
            <Button
               style={{margin:'auto', marginTop: 30, display:'block', maxWidth: 300}}
               color={'primary'}
               variant={'contained'}
               onClick={downloadImage}
            ><Save style={{marginBottom:-5}}/>&nbsp;&nbsp;Save</Button>
         </Grid>
      </Grid>
   </>);
   //return <canvas ref={canvasRef} width={640} height={640} />;
};

export default GenerateImage;

function wrapText(context, text, x, y, maxWidth, lineHeight) {
   var words = text.split(" ");
   var line = "";
   for(var n = 0; n < words.length; n++) {
      var testLine = line + words[n] + " ";
      var metrics = context.measureText(testLine);
      var testWidth = metrics.width;
      if(testWidth > maxWidth) {
         context.fillText(line, x, y);
         line = words[n] + " ";
         y += lineHeight;
      }
      else {
         line = testLine;
      }
   }
   context.fillText(line, x, y);
}

CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius, fill, stroke) {
   var cornerRadius = { upperLeft: 0, upperRight: 0, lowerLeft: 0, lowerRight: 0 };
   if (typeof stroke == "undefined") {
      stroke = true;
   }
   if (typeof radius === "object") {
      for (var side in radius) {
         cornerRadius[side] = radius[side];
      }
   }
   
   this.beginPath();
   this.moveTo(x + cornerRadius.upperLeft, y);
   this.lineTo(x + width - cornerRadius.upperRight, y);
   this.quadraticCurveTo(x + width, y, x + width, y + cornerRadius.upperRight);
   this.lineTo(x + width, y + height - cornerRadius.lowerRight);
   this.quadraticCurveTo(x + width, y + height, x + width - cornerRadius.lowerRight, y + height);
   this.lineTo(x + cornerRadius.lowerLeft, y + height);
   this.quadraticCurveTo(x, y + height, x, y + height - cornerRadius.lowerLeft);
   this.lineTo(x, y + cornerRadius.upperLeft);
   this.quadraticCurveTo(x, y, x + cornerRadius.upperLeft, y);
   this.closePath();
   if (stroke) {
      this.stroke();
   }
   if (fill) {
      this.fill();
   }
}

const dataURLtoFile = (dataurl) => {
   if (!dataurl) {
      return;
   }
   var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
   
   while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
   }
   
   return new File([u8arr], "image.jpg", { type: mime });
};

const shareOnMobile = (params, fallback) => {
   if(navigator === undefined || fallback === undefined){
      return false
   }
   const { url, title, image } = params
   
   if (navigator.share === undefined) {
      fallback("Can't share on this, make sure you are running on Android or iOS devices")
      console.error("error: navigator.share is not available");
      return;
   }
   if (!title) {
      fallback("Title is required")
      console.error("error: title is requied");
      return;
   }
   
   var shareData = { text: title, title };
   if (url) {
      shareData.url = url;
   }
   if (image) {
      var file = dataURLtoFile(image);
      if (file) {
         shareData.files = [file];
      }
   }
   try {
      if (navigator.canShare && navigator.canShare(shareData)) {
         navigator.share(shareData)
            .then(() => console.info('Shared successful.'))
            .catch((error) => {
               //fallback(error.message)
               console.error('Sharing failed', error);
            });
      }
      
   } catch (error) {
      //fallback(error.message)
      console.error("error: ", error);
   }
};
