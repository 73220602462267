import { useQuery } from 'react-query';
import { getResult } from '../api/getResult';

const useResult = (query, companyCountryPhone) => {
  const result = useQuery('search_'+ query+'_'+ companyCountryPhone, () => getResult(companyCountryPhone,query), {
    refetchOnWindowFocus: false,
    staleTime: 5000,
  });
  return result;
};

export default useResult;
