import { useQuery } from 'react-query';
import { getPetAid } from '../api/getPetAid';

const usePetAid = (book,chapter) => {
  const result = useQuery('petaid_'+book+'_'+chapter, () => getPetAid(book,chapter), {
    refetchOnWindowFocus: false,
  });
  return result;
};

export default usePetAid;
