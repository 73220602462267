import { useQuery } from 'react-query';
import { getPetAidCommentary } from '../api/getPetAidCommentary';

const usePetAidCommentary = (book,chapter) => {
  const result = useQuery('petaid_commentary_'+book+'_'+chapter, () => getPetAidCommentary(book,chapter), {
    refetchOnWindowFocus: false,
  });
  return result;
};

export default usePetAidCommentary;
