import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  makeStyles,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogContentText,
  Fab,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Paper, LinearProgress, useTheme, useMediaQuery,
} from '@material-ui/core';
import {Close, EmojiObjects, Flag, LiveHelp, Print, Send} from '@material-ui/icons';
import Convo from './Convo';
import useCurrentUser from '../../lib/hooks/useCurrentUser';
import useEmbeddableChat from '../../lib/hooks/useEmbeddableChat';
import useOrganization from '../../lib/hooks/useOrganization';
import { prettyDateTime, truncate } from '../../lib/constants/helpers';
import { opacity } from '../../lib/themes';
import cn from 'classnames';
import IndexTicker from "../IndexTicker";

const useStyles = makeStyles(theme => ({
  chatFormClosed: {
    display: 'none',
    position: 'absolute',
    zIndex: -1,
  },
  chatForm: {
    transition: 'all 0.3s ease',
    '& *': {
      transition: 'all 0.3s ease',
    },
    borderStyle: 'solid',
    borderWidth: 2,
    backdropFilter: 'blur(5px)',
    borderRadius: 5,
    minWidth: 400,
    maxHeight: 700,
    display: 'block',
    fontSize: 14,
  },
  chatFormDark: {
    borderColor: theme.palette.grey['500'],
    backgroundColor: opacity('#0F1418', 0.8) || 'rgba(255,255,255, 0.3)' || theme.palette.backgrounds.paper,
    boxShadow: '0 0 16px 0 rgba(255, 255, 255, 0.5) !important',
    color: theme.palette.primary.paperText,
  },
  chatFormLight: {
    borderColor: 'transparent' || theme.palette.grey['200'],
    backgroundColor: 'rgba(255,255,255, 0.2)' || theme.palette.backgrounds.paper,
    boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.2) !important',
    color: theme.palette.primary.paperText,
  },
  closeButton: {
    float: 'right',
    marginRight: 0,
    color: theme.palette.primary.contrastText,
  },
  clear: {
    clear: 'both',
  },
  pageTitle: {
    fontSize: 24,
    marginBottom: 0,
  },
  title: {
    fontSize: 16,
  },
  statusText: {
    fontSize: 14,
  },
}));

const ProviderChat = ({
  variant = 'right',
  buttonGradient = false,
  forceOpen = true,
  organizationID = null,
  contactID = null,
  colors = ['#e46db0', '#fd5c87', '#9e9ea8', '#f6f9fc'],
}) => {
  const classes = useStyles();
  const [chatOpen, setChatOpen] = useState(false);
  const [convo, setConvo] = useState([]);
  const [isConvoMode, setIsConvoMode] = useState(true);
  const [newMessage, setNewMessage] = useState(null);
  const { userID } = useCurrentUser();
  const darkMode = useMemo(
    () =>
      localStorage.getItem('THEME') === 'true' ||
      (!localStorage.getItem('THEME') &&
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches),
    [localStorage.getItem('THEME')],
  );

  const visitorID = useMemo(() => {
    if (!contactID && !userID) {
      // no contact or user, so assume visitor
      if (localStorage.getItem('PA')) {
        return localStorage.getItem('PA');
      } else {
        return localStorage.setItem('PA', uuidv4());
      }
    } else {
      return null;
    }
  }, [contactID, userID]);
  const {
    isLoading: isLoadingConvo,
    isRefetching: isRefetchingConvo,
    data,
    refetch: refetchConvo,
  } = useEmbeddableChat(organizationID, contactID, visitorID);

  const {
    data: organization,
  } = useOrganization(organizationID);
  const {
    show_powered_by,
    system_status, // 'Issue with Unsubcribe Metrics for region US-East-1, estimated fix at 18:00',
    system_status_last_updated,
  } = organization ? organization : [];

  useEffect(() => {
    if (convo && convo.length) {
      setIsConvoMode(true);
    }
  }, [convo]);

  useEffect(() => {
    if (forceOpen) {
      setChatOpen(forceOpen);
    }
  }, [forceOpen]);

  const handleToggleOpen = useCallback(() => {
    setChatOpen(!chatOpen);
  }, [chatOpen]);

  const handlePrint = useCallback(() => {
    window.print();
  }, []);


  useEffect(() => {
    const callback = event => {
      if (!isConvoMode) {
        if (event.code === 'Enter') {
          event.preventDefault();
          setIsConvoMode(true);
        }
      }
      if (event.code === 'Escape') {
        if (isConvoMode) {
          setIsConvoMode(false);
        } else {
          setChatOpen(false);
        }
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [isConvoMode]);
  
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
  return (
    <>
  
      <Card style={{marginTop: matchSM ? 20 : 0}}>
        <CardContent>
          <Paper
            className={
              chatOpen
                ? cn(classes.chatForm, darkMode ? classes.chatFormDark : classes.chatFormLight)
                : classes.chatFormClosed
            }
          >
            <Grid
              container
              item
              xs={12}
              justifyContent={'space-between'}
              alignItems={'center'}
              style={{ margin: '5px auto auto', textAlign: 'center' }}
            >
              <Grid item xs={1}></Grid>
              <Grid item xs={10}>
                <Typography className={classes.pageTitle}>
                  <EmojiObjects style={{ marginBottom: -4 }} /> AI Diagnosis
                </Typography>
              </Grid>
              <Grid item xs={1}>
              
              </Grid>
            </Grid>
            
            {isLoadingConvo && <LinearProgress style={{ margin: '20px auto 20px' }} />}
              <Convo
                colors={colors}
                initialMessage={newMessage}
                organizationID={organizationID}
                visitorID={visitorID}
                contactID={contactID}
                convo={convo}
                setConvo={setConvo}
                refetch={refetchConvo}
                setInitialMessage={setNewMessage}
              />
          </Paper>
          <IndexTicker style={{ margin: '20px auto 0px' }}/>
        </CardContent>
      </Card>
      
        <>
          <Card style={{ marginTop: 15, minHeight: 50, height: 'auto' }}>
            <CardContent style={{ paddingBottom: 5, paddingTop: 5 }}>
              <Grid
                 container
                 item
                 xs={12}
                 justifyContent={'space-between'}
                 alignItems={'center'}
                 style={{ margin: '5px auto auto', textAlign: 'center' }}
              >
                <Grid item xs={1}>
                  <Flag />
                </Grid>
                <Grid item xs={10}>
                  <Tooltip title={system_status ?? ''}>
                    <Typography className={classes.statusText}>
                      Status: {truncate(system_status ? system_status : 'No Issues', 50)}
                    </Typography>
                  </Tooltip>
                  <Typography color={'textSecondary'} className={classes.statusText}>
                    Updated: {prettyDateTime(system_status_last_updated ?? new Date())}
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
              
              {1 == 2 || show_powered_by && (
                 <DialogActions style={{ marginTop: 5 }}>
                   <DialogContentText style={{ textAlign: 'center', paddingTop: 15, margin: '-25px auto 0px' }}>
                     <a
                        href={'https://petaid.ai?referrer=' + window.location.href}
                        target={'_blank'}
                        rel="noreferrer"
                        style={{ color: 'inherit' }}
                     >
                       Powered by PetAid.ai
                     </a>
                   </DialogContentText>
                 </DialogActions>
              )}
            </CardContent>
          </Card>
        </>
    </>
  );
};
function uuidv4() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16),
  );
}

export default ProviderChat;
