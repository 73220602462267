import BOOK from '../../assets/en_kjv_bible.json'
import React, {useContext} from "react";
import {Route, Link, Routes} from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
   Typography,
   Drawer,
   List,
   ListItem,
   ListItemText,
   Button,
   Card,
   CardContent,
   Grid,
   CircularProgress,
   ListItemAvatar,
   Avatar,
   IconButton, Box,
} from "@material-ui/core";
import {getNameFromAbbreviation} from "../../lib/constants/helpers";
import Logo from "../../assets/logo.png";
import {SearchContext} from "../../lib/contexts";
import {Menu} from "@material-ui/icons";
import Commentary from "./Commentary";
import WordCloud from "../WordCloud/WordCloud";
import BibleVerseCountChart from "../BookCharts/BibleVerseCountChart";
import BibleCharacterNetworkGraph from "../BookCharts/BibleCharacterNetworkGraph";
import BibleEventList from "../BookCharts/BibleEventList";

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
   },
   appBar: {
      zIndex: theme.zIndex.drawer + 1,
   },
   drawer: {
      width: 380,
      flexShrink: 0,
   },
   drawerPaper: {
      width: 380,
   },
   drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
   },
   content: {
      flexGrow: 1,
      padding: theme.spacing(3),
   },
   title: {
      flexGrow: 1,
   },
}));

const RenderBook = () => {
   const { state: {matchSM, menuOpen, bookAbbrev}, actions: {toggleMenu} } = useContext(SearchContext);
   
   const booksListItems = BOOK.map((book,index) => (
      <ListItem button key={book.abbrev} component={Link} to={`/${getNameFromAbbreviation(book.abbrev)}`}>
         <ListItemAvatar key={book.abbrev}>
            <Avatar style={{width:40,height:40}}>{index+1}</Avatar>
         </ListItemAvatar>
         <ListItemText primary={<>{getNameFromAbbreviation(book.abbrev)}</>}/>
      </ListItem>
   ));
   
   return (
      <>
         <Drawer variant={matchSM ? "temporary" : "permanent"} anchor="left" open={menuOpen} onClose={toggleMenu}>
            <a href={'../../'} style={{display:'block',margin:'10px auto auto',width:100}}>
               <img src={Logo} style={{margin:'10px auto auto',width:'100%'}}/>
            </a>
            <br/>
            <stripe-buy-button
               buy-button-id="buy_btn_1MyPrTJNj13cAQxS82WKUt3B"
               publishable-key="pk_live_51MyPbeJNj13cAQxSvscabauhA5XMMKRinqAuZ9sG2Trki2P6mbIXdWroLC61dkD0Y9ExCbQIqyIiF79HfmKVA8Is00cXvG9I8K"
            >
            </stripe-buy-button>
            {/*<DarkModeToggle/>*/}
            <List style={{width:250, overflowY:'auto'}}>{booksListItems}</List>
         </Drawer>
         <main style={{marginLeft: bookAbbrev ? matchSM ? 20 : 310 : matchSM ? 20 : 270, marginRight:20}}>
            {bookAbbrev ? <BookContent/> : <HomePage/>}
         </main>
      </>
   );
};

const BookContent = () => {
   const { state: {matchSM, bookAbbrev} } = useContext(SearchContext);
   const book = BOOK.find((b) => getNameFromAbbreviation(b.abbrev) === bookAbbrev);
   
   if (!book) {
      return <Typography variant="body1">Book not found</Typography>;
   }
   
   const chapters = book.chapters.map((chapter, index) => (
      <Button key={index} component={Link} to={`/${getNameFromAbbreviation(book.abbrev)}/${index + 1}`}>
         Chapter {index + 1}
      </Button>
   ));
   
   return (
      <>
         {matchSM && <Header/>}
         
         <Card>
         <CardContent style={{maxHeight: matchSM ? 200 : 320, overflowY:'auto', paddingBottom:10}}>
         <Typography variant="h4">{getNameFromAbbreviation(book.abbrev)}</Typography>
         <div>{chapters}</div>
         </CardContent>
         </Card>
         <Routes>
            <Route path=":chapterNumber" element={<PageLayout/>} />
         </Routes>
      </>
   );
};
const ChapterContent = () => {
   const { state: {bookAbbrev, chapterNumber} } = useContext(SearchContext);
   const book = BOOK.find((b) => getNameFromAbbreviation(b.abbrev) === bookAbbrev);
   
   if (!book) {
      return <Typography variant="body1">Book not found</Typography>;
   }
   
   const chapter = book.chapters[chapterNumber - 1];
   
   if (!chapter) {
      return <Typography variant="body1">Chapter not found</Typography>;
   }
   
   const verses = chapter.map((verse, index) => (
      <React.Fragment key={index}>
         <h3 style={{display:'inline'}}>{index + 1}.</h3> <Typography key={index} variant="body1" style={{display:'inline'}}>{verse}</Typography>
         <br/>
      </React.Fragment>
   ));
   
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
         <Typography variant="h4" style={{marginBottom:10}}>
            {getNameFromAbbreviation(book.abbrev)} {chapterNumber}
         </Typography>
         <div>{verses}</div>
      </CardContent>
      </Card>
   );
};
const PageLayout = () => {
   const { state: {matchSM} } = useContext(SearchContext);
   return (<>
      <Grid container item xs={12} spacing={matchSM ? 0 : 2} justifyContent={"space-between"}>
         <Grid item xs={matchSM ? 12 : 8} style={{ order: matchSM ? 2 : 1 }}><ChapterContent /></Grid>
         <Grid item xs={matchSM ? 12 : 4} style={{ order: matchSM ? 1 : 2 }}>
            <SidebarWho/>
            <SidebarWhat/>
            <SidebarWhy/>
            <SidebarWhen/>
            <SidebarFacts/>
            <SidebarMytho/>
         </Grid>
         <Grid item xs={12} style={{ order: 3 }}>
         <Commentary/>
         </Grid>
      </Grid>
   </>
   );
};
const SidebarWho = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { Who } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Who
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               <div>{ Who }</div>
            </>}
         </CardContent>
      </Card>
   );
};
const SidebarWhat = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { What } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               What
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               <div>{ What }</div>
            </>}
         </CardContent>
      </Card>
   );
};
const SidebarWhy = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { Why } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Why
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               <div>{ Why }</div>
            </>}
         </CardContent>
      </Card>
   );
};
const SidebarWhen = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { When } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               When
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               <div>{ When }</div>
            </>}
         </CardContent>
      </Card>
   );
};

const SidebarFacts = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { Fun_Facts } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Fun Facts
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               { Boolean(Fun_Facts) && Fun_Facts.map(fact=><ListItem>{fact}</ListItem>) }
            </>}
         </CardContent>
      </Card>
   );
};
const SidebarMytho = () => {
   const {
      state: {bibleGPTLoading, bibleGPTData}
   } = useContext(SearchContext);
   const { Unofficial_Mythology } = bibleGPTData || {}
   return (
      <Card style={{marginTop:20}}>
         <CardContent>
            <Typography variant="h5">
               Unofficial Mythology
            </Typography>
            {bibleGPTLoading ? <CircularProgress style={{display:'block',margin:'20px auto auto'}}/> : <>
               <div>{ Unofficial_Mythology }</div>
            </>}
         </CardContent>
      </Card>
   );
};
const HomePage = () => {
   const { state: {matchSM} } = useContext(SearchContext);
   return (<>
         {matchSM && <Header/>}
      <Box style={{marginTop: matchSM ? -20 : 0, marginLeft: matchSM ? 0 :40, marginRight: matchSM ? -15 : 0}}>
         <Grid container item xs={12} spacing={2}>
         <Grid item xs={matchSM ? 12 : 6}>
            <WordCloud/>
         </Grid>
         <Grid item xs={matchSM ? 12 : 6}>
            <BibleEventList/>
            <BibleCharacterNetworkGraph/>
         </Grid>
         <Grid item xs={12}>
            <BibleVerseCountChart/>
         </Grid>
         <Grid item xs={12}>
         </Grid>
         </Grid>
      </Box>
      </>
   );
};

const Header = () =>{
   const { state: {matchSM}, actions: {toggleMenu} } = useContext(SearchContext);
   return (<Card style={{height:80, marginBottom:20, marginLeft: matchSM ? 0 : 40, marginRight: matchSM ? 0 : 15}}>
      <Grid container item xs={12} justifyContent={"space-between"}>
         <Grid item xs={4}>
            {matchSM && <IconButton onClick={toggleMenu} style={{marginTop: 17}}>
               <Menu/>
            </IconButton>}
         </Grid>
         <Grid item xs={4} style={{textAlign:'center',margin:'auto'}}>
            <a href={'../../'} style={{display:'block',margin:'0px auto auto',width:100}}>
               <img src={Logo} style={{margin:'10px auto auto',height:55}}/>
            </a>
         </Grid>
         <Grid item xs={4}>
            &nbsp;
         </Grid>
      </Grid>
   </Card>)
}

export default RenderBook;









