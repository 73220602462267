import { useQuery } from 'react-query';
import { getEmbeddableChat } from '../api';
import useCurrentUser from "./useCurrentUser";
const useEmbeddableChat = (organizationId, contactId, visitorId) => {
   const { token, userID } = useCurrentUser();
   const result = useQuery(
    'chat_' + organizationId + '_' + contactId + '_' + visitorId,
    () => getEmbeddableChat(organizationId, contactId, visitorId, token, userID),
    {
      refetchOnWindowFocus: true,
      refetchInterval: 3000,
      refetchIntervalInBackground: 5000,
    },
  );
  return result;
};

export default useEmbeddableChat;
