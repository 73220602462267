import React, {useState, useEffect, useContext} from 'react';
import {
   Card,
   CardContent,
   TextField,
   Typography,
   Grid,
   MenuItem, useMediaQuery, useTheme
} from '@material-ui/core';
import TEMPLATE from '../../lib/constants/pet_template.json'
import SPECIES_TEMPLATE from '../../lib/constants/species.json'
import { toCapitalizeFromUnderScore} from "../../lib/constants/helpers";
import {SearchContext} from "../../lib/contexts";
const PatientForm = () => {
   const { actions: {setSharedPatientData} } = useContext(SearchContext);
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   
   const [patientData, setPatientData] = useState(TEMPLATE);
   const handleInputChangeLastWorking = (event, path) => {
      const value = event.target.value;
      
      setPatientData((prevState) => {
         const newData = { ...prevState };
         let current = newData;
         
         for (let i = 0; i < path.length - 1; i++) {
            current = current[path[i]];
         }
         current[path[path.length - 1]] = value;
         
         return newData;
      });
   };
   const handleInputChange = (event, path) => {
      const value = event.target.value;
      
      setPatientData((prevState) => {
         const newData = { ...prevState };
         let current = newData;
         
         for (let i = 0; i < path.length - 1; i++) {
            current = current[path[i]];
         }
         
         if (Array.isArray(current[path[path.length - 1]])) {
            current[path[path.length - 1]] = value;
         } else {
            current[path[path.length - 1]] = value;
         }
         
         return newData;
      });
      handleSubmit(); // auto update
   };
   
   useEffect(()=>{
      handleSubmit() //auto update
   },[patientData])
   
   
   const handleSubmitLastWorking = () => {
      const cleanedData = JSON.parse(JSON.stringify(patientData, (key, value) => (value === null ? undefined : value)));
//      console.log(convertJsonToYaml(cleanedData)); console.log(jsonToCsv(cleanedData));
      setSharedPatientData(cleanedData)
   };
   const handleSubmit = () => {
      const cleanDataRecursively = (data) => {
         return Object.fromEntries(
            Object.entries(data).map(([key, value]) => {
               if (typeof value === 'object' && Array.isArray(value) && value.length > 0) {
                  return [key, undefined];
               } else if (typeof value === 'object' && !Array.isArray(value)) {
                  return [key, cleanDataRecursively(value)];
               } else {
                  return [key, value];
               }
            })
         );
      };
      
      const cleanedData = JSON.parse(
         JSON.stringify(cleanDataRecursively(patientData), (key, value) => (value === undefined ? undefined : value))
      );
      setSharedPatientData(cleanedData);
   };
   
   const renderFieldsLastWorking = (obj, path = [], noCard = false) => {
      return Object.keys(obj).map((key) => {
         const newPath = path.concat(key);
         const value = obj[key];
         if (typeof value === 'object' && !Array.isArray(value)) {
            return (<React.Fragment key={key}>
                  {noCard ? <Grid item xs={12} style={{marginTop:20}}>
                        <Typography variant="h6">{toCapitalizeFromUnderScore(key)}</Typography>
                        <Grid container spacing={matchSM ? 0 : 2}>
                           {renderFields(value, newPath, true)}
                        </Grid>
                     </Grid> :
                     <Grid container item xs={matchSM ? 12 : 4} key={key} style={{marginTop:20}}>
                     <Card>
                        <CardContent>
                           <Typography variant="h5">{toCapitalizeFromUnderScore(key)}</Typography>
                           <Grid container spacing={matchSM ? 0 : 2}>
                              {renderFields(value, newPath, true)}
                           </Grid>
                        </CardContent>
                     </Card>
                     </Grid>}
               </React.Fragment>
            );
         } else {
            return (
               <Grid key={key} item xs={12}>
                  <TextField
                     label={toCapitalizeFromUnderScore(key)}
                     value={value === null ? '' : value}
                     onChange={(e) => handleInputChange(e, newPath)}
                     fullWidth
                  />
               </Grid>
            );
         }
      });
   };
   
   const renderFields2 = (obj, path = [], noCard = false) => {
      return Object.keys(obj).map((key) => {
         const newPath = path.concat(key);
         const value = obj[key];
         if (typeof value === 'object' && !Array.isArray(value)) {
            return (
               <React.Fragment key={key}>
                  {noCard ? (
                     <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography variant="h6">{toCapitalizeFromUnderScore(key)}</Typography>
                        <Grid container spacing={matchSM ? 0 : 2}>
                           {renderFields(value, newPath, true)}
                        </Grid>
                     </Grid>
                  ) : (
                     <Grid container item xs={matchSM ? 12 : 4} key={key} style={{ marginTop: 20 }}>
                        <Card>
                           <CardContent>
                              <Typography variant="h5">{toCapitalizeFromUnderScore(key)}</Typography>
                              <Grid container spacing={matchSM ? 0 : 2}>
                                 {renderFields(value, newPath, true)}
                              </Grid>
                           </CardContent>
                        </Card>
                     </Grid>
                  )}
               </React.Fragment>
            );
         } else if (Array.isArray(value) && value.length > 0) {
            return (
               <Grid key={key} item xs={12}>
                  <TextField
                     select
                     label={toCapitalizeFromUnderScore(key)}
                     value={patientData}
                     onChange={(e) => handleInputChange(e, newPath)}
                     fullWidth
                  >
                     {value.map((option) => (
                        <MenuItem key={option} value={option}>
                           {toCapitalizeFromUnderScore(option)}
                        </MenuItem>
                     ))}
                  </TextField>
               </Grid>
            );
         } else {
            return (
               <Grid key={key} item xs={12}>
                  <TextField
                     label={toCapitalizeFromUnderScore(key)}
                     value={value === null ? '' : value}
                     onChange={(e) => handleInputChange(e, newPath)}
                     fullWidth
                  />
               </Grid>
            );
         }
      });
   };
   
   
   
   
   const renderSpeciesField = (key, path, handleInputChange) => {
      const speciesOptions = SPECIES_TEMPLATE.species.map((species) => species.name);
      
      return (
         <Grid key={key} item xs={12}>
            <TextField
               select
               label={toCapitalizeFromUnderScore(key)}
               value={patientData.patient_info.species}
               onChange={(e) => handleInputChange(e, ["patient_info", key])}
               fullWidth
            >
               {speciesOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                     {option}
                  </MenuItem>
               ))}
            </TextField>
         </Grid>
      );
   };
   
   const renderBreedField = (key, path, handleInputChange, species) => {
      const selectedSpecies = patientData.patient_info.species;
      const breedOptions =
         SPECIES_TEMPLATE.species.find((s) => s.name === selectedSpecies)?.breeds ?? [];
      
      return (
         <Grid key={key} item xs={12}>
            <TextField
               select
               label={toCapitalizeFromUnderScore(key)}
               value={patientData.patient_info.breed}
               onChange={(e) => handleInputChange(e, ["patient_info", key])}
               fullWidth
            >
               {breedOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                     {option}
                  </MenuItem>
               ))}
            </TextField>
         </Grid>
      );
   };
   
   const renderEnumField = (key, value, fieldPath) => {
      const fieldValue = patientData[fieldPath];
      const options = TEMPLATE[fieldPath[0]][key] || [];
      if (!options || !options.length) return null;
   
      return (
         <Grid key={key} item xs={12}>
            <TextField
               select
               label={toCapitalizeFromUnderScore(key)}
               value={fieldValue && fieldValue.length > 0 ? fieldValue[0] : ""}
               onChange={(e) => handleInputChange(e, fieldPath)}
               fullWidth
            >
               {options.map((option) => (
                  <MenuItem key={option} value={option}>
                     {option}
                  </MenuItem>
               ))}
            </TextField>
         </Grid>
      );
   };
   
   const renderTextField = (key, value, fieldPath) => {
      return (
         <Grid key={key} item xs={12}>
            <TextField
               label={toCapitalizeFromUnderScore(key)}
               value={value}
               onChange={(e) => handleInputChange(e, fieldPath)}
               fullWidth
            />
         </Grid>
      );
   };
   
   
   const renderFields = (obj, path = [], noCard = false) => {
      return Object.keys(obj).map((key) => {
         const newPath = path.concat(key);
         const value = obj[key];
         if (typeof value === "object" && !Array.isArray(value)) {
            return (
               <React.Fragment key={key}>
                  {noCard ? (
                     <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography variant="h6">
                           {toCapitalizeFromUnderScore(key)}
                        </Typography>
                        <Grid container spacing={matchSM ? 0 : 2}>
                           {renderFields(value, newPath, true)}
                        </Grid>
                     </Grid>
                  ) : (
                     <Grid container item xs={matchSM ? 12 : 4} key={key} style={{ marginTop: 20 }}>
                        <Card>
                           <CardContent>
                              <Typography variant="h5">
                                 {toCapitalizeFromUnderScore(key)}
                              </Typography>
                              <Grid container spacing={matchSM ? 0 : 2}>
                                 {renderFields(value, newPath, true)}
                              </Grid>
                           </CardContent>
                        </Card>
                     </Grid>
                  )}
               </React.Fragment>
            );
         } else {
            if (key === "species") {
               return renderSpeciesField(key, newPath, handleInputChange);
            } else if (key === "breed") {
               return renderBreedField(key, newPath, handleInputChange);
            } else if (Array.isArray(value)) {
               return renderEnumField(key, value, newPath, handleInputChange);
            } else {
               return renderTextField(key, value, newPath, handleInputChange);
            }
         }
      });
   };
   
   return (<>
         <form>
            <Grid container spacing={matchSM ? 0 : 4} xs={12} item>
               {renderFields(patientData)}
            </Grid>
         </form>
      </>
   );
};
export default PatientForm;
