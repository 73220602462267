import axios from 'axios';
import { API_HOST } from '../constants/constants';
export const postEmbeddableChat = async (object, token, userID) => {
  const apiURL = API_HOST + 'petaid/diagnosis';
  
  try {
    const { data } = await axios.post(apiURL,{
        ...object
    },{
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer '+token,
        'x-sb-user-id': userID
      }
    });
    return data;
  } catch (error) {
    console.error(error);
  }
};
