import React, {useContext, useMemo} from 'react';
import {
   Avatar,
   Card,
   CardActions,
   CardContent, Grid,
   IconButton,
   Link,
   makeStyles,
   Typography, useMediaQuery, useTheme
} from '@material-ui/core';

import {CheckCircle, Close, Favorite, Report, Share} from "@material-ui/icons";
import {CLIENT_HOST} from "../../lib/constants/constants";
import {SearchContext} from "../../lib/contexts";
import {toCapitalize, toCapitalizeFromUnderScore} from "../../lib/constants/helpers";

const useStyles = makeStyles(theme => ({
   searchResult: {
      width: '100%',
      cursor: 'pointer'
   },
   title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 12,
      color: theme.palette.primary.title
   },
   titleMobile: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 12,
      color: theme.palette.primary.title
   },
   host: {
      color: theme.palette.primary.host,
      marginBottom: 6,
   },
   desc: {
   },
   descMobile: {
      fontSize: 14,
   },
   favicon: {float:'left', width: 16, height: 16, marginRight: 4, marginTop: 4}
}));
const SearchResultItem = ({item}) => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   const {
      state: { type },
   } = useContext(SearchContext);
   
   const title = useMemo(()=>type && item.page_index ? (item.name || item.headline || item.caption || item.alternativeHeadline || item.alternateName || item.title || item.page_index.title) : item.title,[type, item])

   return(
<Card className={classes.searchResult}>
   <CardContent>
      <Grid container item justifyContent={'space-between'}>
         <Grid item xs={matchSM ? 12 : 9}>
            <Typography variant="h5" component="h2" className={matchSM ? classes.titleMobile : classes.title}>
               {item.national_format}
            </Typography>
            <Typography variant="h5" component="h2" className={matchSM ? classes.titleMobile : classes.title}>
               {item.carrier || item.portability.spid_carrier_name}
            </Typography>
            <Typography color="textSecondary">
               Name: {item.caller_name ?? 'N/A'}
            </Typography>
            <Typography className={matchSM ? classes.descMobile : classes.desc}>
               Type: {toCapitalizeFromUnderScore(item.portability.line_type)}
            </Typography>
            <Typography className={matchSM ? classes.descMobile : classes.desc}>
               {toCapitalize(item.portability.city)}, {toCapitalize(item.portability.state)}
            </Typography>
            <Typography className={matchSM ? classes.descMobile : classes.desc}>
               Flagged for Abuse? {item.fraud ? <CheckCircle style={{marginBottom:-5}}/> : <Close style={{marginBottom:-5}}/>}
            </Typography>
         </Grid>
      </Grid>
   </CardContent>

   <CardActions disableSpacing style={{display:'none'}}>
      <Link href={CLIENT_HOST+'/save?item='+item.uuid} target="_blank" rel="noreferrer">
         <IconButton aria-label="Remember Result">
            <Favorite />
         </IconButton>
      </Link>
      <Link href={CLIENT_HOST+'/share?item='+item.uuid} target="_blank" rel="noreferrer">
         <IconButton aria-label="Share Result">
            <Share />
         </IconButton>
      </Link>
      <Link href={CLIENT_HOST+'/report?item='+item.uuid} target="_blank" rel="noreferrer">
         <IconButton aria-label="Share Result">
            <Report />
         </IconButton>
      </Link>
   </CardActions>
</Card>
)
};

export default SearchResultItem;
