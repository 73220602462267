import React, {useCallback, useMemo, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import {Card, CardContent, Divider, Grid, IconButton, useMediaQuery, useTheme} from '@material-ui/core';
import BioBox from './components/bioBox';
import SearchFormBox from './components/SearchFormBox';
//import TrendingBox from "./components/TrendingBox";
import TitleBox from "./components/TitleBox";
import {SearchContextProvider, ThemeContext} from "./lib/contexts";
import IndexTicker from "./components/IndexTicker";
import DarkModeToggle from "./components/DarkModeToggle";
import { QueryClient, QueryClientProvider } from 'react-query';
import { LightTheme, DarkTheme } from './lib/themes';
import RandomQuote from "./components/RandomQuote";
import ShareBox from "./components/ShareBox";
import SponsoredBox from "./components/SponsoredBox";
import RenderBook from "./components/RenderBook";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import PatientForm from "./components/PatientForm";
import ProviderChat from "./components/ProviderChat/ProviderChat";
import {Print} from "@material-ui/icons";
import {SnackbarProvider} from "notistack";

const useStyles = makeStyles({
   root: {
      padding:'2.5%',
      width: '100%'
   },
   smallCard: {
      minWidth: 200
   },
   bigCard: {
      margin:'auto auto auto',
      width:'100%'
   }
});

const App = () => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   //const matchMDD = useMediaQuery(theme.breakpoints.only('md'));
   //const matchMD = useMediaQuery(theme.breakpoints.up('md'));
   //const matchLG = useMediaQuery(theme.breakpoints.only('lg'));
   //const matchXL = useMediaQuery(theme.breakpoints.up('xl'));
   return (
       <Grid
          className={classes.root +" mobile"}
          container
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          spacing={matchSM ? 1 : 6}
       >
          <Grid item xs={!matchSM ? 7 : 12}>
   
             <Card >
                <CardContent>
                   <Grid
                      justifyContent="space-between"
                      container
                      item
                      xs={12}>
                      <Grid item xs={matchSM ? 6 : 4}>
                         <TitleBox/>
                      </Grid>
                      <Grid item xs={matchSM ? 1 : 7}>
                      </Grid>
                      <Grid item xs={1}>
                         {/* <DarkModeToggle/>*/}
                         <IconButton onClick={()=>window.print()} className={classes.closeButton}>
                            <Print />
                         </IconButton>
                      </Grid>
                   </Grid>
                </CardContent>
             </Card>
             {!matchSM && <PatientForm />}
             {/*
               {!matchSM && <ShareBox/>}
               <TrendingBox/>
             <CopySettingsBox/>
             */}
          </Grid>
          <Grid item xs={!matchSM ? 5 : 12}>
          {matchSM && <PatientForm/>}
             <ProviderChat/>
             <RandomQuote/>
          </Grid>
          </Grid>
  );
}
const ThemeContainer = () => {
   const [darkMode, setDarkMode] = useState(true || window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches);
   const toggleTheme = useCallback(() => {
      setDarkMode(!darkMode);
   },[darkMode]);
   const chosenTheme = useMemo(()=>darkMode ? DarkTheme() : DarkTheme(),[darkMode]); // LightTheme()
   return (
      <ThemeContext.Provider value={{darkMode,toggleTheme}}>
      <ThemeProvider theme={chosenTheme}>
         <BrowserRouter>
         <SearchContextProvider>
            <SnackbarProvider
               anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
               variant={'info'}
               autoHideDuration={4000}
               preventDuplicate={true}
               maxSnack={6}
            >
               <App/>
            </SnackbarProvider>
            {/*<Routes>
               <Route path="/:bookAbbrev/*" element={<RenderBook />} />
               <Route path="*" element={<RenderBook />} />
            </Routes>*/}
         </SearchContextProvider>
         </BrowserRouter>
      </ThemeProvider>
      </ThemeContext.Provider>
   );
};

const AppContainer = () => {
   const queryClient = new QueryClient();
   return (
      <QueryClientProvider client={queryClient}>
               <ThemeContainer />
      </QueryClientProvider>
   );
};

export default AppContainer;
