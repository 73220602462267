import React, {useContext} from 'react';
import {
   Avatar,
   Card,
   CardContent, Grid,
   makeStyles,
   Typography, useMediaQuery, useTheme
} from '@material-ui/core';
import {SearchContext} from "../../lib/contexts";
import GenerateImage from "../ShareBox/GenerateImage";
import {removeFromBeginning} from "../../lib/constants/helpers";


const useStyles = makeStyles(theme => ({
   searchResult: {
      width: '100%',
      cursor: 'pointer'
   },
   title: {
      fontSize: 20,
      fontWeight: 'bold',
      marginBottom: 12,
      color: theme.palette.primary.title
   },
   titleMobile: {
      fontSize: 16,
      fontWeight: 'bold',
      marginBottom: 12,
      color: theme.palette.primary.title
   },
   host: {
      color: theme.palette.primary.host,
      marginBottom: 6,
   },
   desc: {
   },
   descMobile: {
      fontSize: 14,
   },
   favicon: {float:'left', width: 16, height: 16, marginRight: 4, marginTop: 4}
}));
const AIResultItem = ({item, photo}) => {
   const classes = useStyles();
   const theme = useTheme();
   const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
   const {
      state: {personality, debounceQuery },
   } = useContext(SearchContext);
   
   return(
<Card className={classes.searchResult}>
   <CardContent>
      <Grid container item justifyContent={'space-between'}>
         <Grid item xs={12}>
            <GenerateImage imageUrl={personality.photo} question={debounceQuery}  name={personality.name} answer={removeFromBeginning(item.text)}/>
            {
               /*
            <Avatar variant={'rounded'} src={personality.photo} style={{width: matchSM ? 50 : 80, height: matchSM ? 50 : 80, float:'left', marginRight:20, marginBottom: 20}}/>
            <Typography variant="h5" component="h2" className={matchSM ? classes.titleMobile : classes.title}>
               {item.text}
            </Typography>
               * */
            }
         </Grid>
      </Grid>
   </CardContent>
</Card>
)
};

export default AIResultItem;
