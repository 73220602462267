const useCurrentUser = () => {
  const userObject = JSON.parse(localStorage.getItem('AUTH')) || JSON.parse(sessionStorage.getItem('AUTH')) || undefined;
  return {
    token: userObject ? userObject.auth_token : undefined,
    userID: userObject ? userObject.user_id : undefined,
    user: userObject ? userObject : undefined,
  }
};

export default useCurrentUser;
