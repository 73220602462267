import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import {
  makeStyles,
  CircularProgress,
  DialogActions,
  Box,
  IconButton,
  TextField,
  Typography, Button, useTheme, useMediaQuery,
} from '@material-ui/core';
import { ChatBubble, Send } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import Avatar from 'boring-avatars';
import { prettyDateTime } from '../../lib/constants/helpers';
import { postEmbeddableChat } from '../../lib/api';
import useCurrentUser from "../../lib/hooks/useCurrentUser";
import './style.css'
import {SearchContext} from "../../lib/contexts";
const useStyles = makeStyles(theme => ({
  '@keyframes Lmuha': {
    '100%': {
      transform: 'scale(1)',
    },
  },
  chatBubble: {
    animation: 'Lmuha 0.3s ease forwards',
    background: '#e46db0',//'#1b9feb', //'#1e8555',
    borderRadius: '18px 18px 18px 0',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.20)',
    color: '#fff', //'#000',
    display: 'inline-block',
    fontSize: 14,
    maxWidth: '50%',
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    padding: 12,
    transform: 'scale(0)',
    transformOrigin: 'bottom left',
  },
  chatBubbleUser: {
    animation: 'Lmuha 0.3s ease forwards',
    background: '#E5E5EA', //'#1e8555',
    borderRadius: '18px 18px 0px 18px',
    boxShadow: '0 1px 2px 0 rgba(0,0,0,0.20)',
    color: '#000', //'#000',
    display: 'inline-block',
    fontSize: 14,
    maxWidth: '50%',
    margin: 0,
    overflow: 'hidden',
    position: 'relative',
    padding: 12,
    transform: 'scale(0)',
    transformOrigin: 'bottom left',
  },
  timestampLeft: {
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    display: 'block',
    margin: '5px 0 10px',
    float: 'none',
    textAlign: 'right',
  },
  timestampRight: {
    color: theme.palette.primary.contrastText,
    fontSize: 10,
    display: 'block',
    margin: '5px 0 10px',
    float: 'none',
  },
  avatar: {
    animation: 'Lmuha 0.3s ease forwards',
    borderRadius: '50% 50% 0 50%',
    boxShadow: 'rgba(0,0,0,0) 0px 1px 2px 0px',
    height: 40,
    minWidth: 40,
    padding: 3,
    transform: 'scale(0)',
    transformOrigin: 'bottom right',
    width: 40,
  },
  container: {
    display: 'inline-block',
    order: 0,
    padding: 6,
  },
  fromOrganization: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  fromUser: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  convoContainer: {
    overflowY: 'auto',
    maxHeight: '420px',
  },
}));
const MessageBubble = ({ row, orgIcon, colors, organization }) => {
  const classes = useStyles();
  return (
    <div>
      <div className={!organization ? classes.fromUser : classes.fromOrganization}>
        {!organization && <div className={classes.chatBubbleUser}>{row.message}</div>}
        <div className={classes.container}>
          <Avatar
            className="avatar"
            size={45}
            src={orgIcon}
            name={
              organization
                ? 'Dr. PetAid' || row.organization_id
                : 'Patient' || row?.user_id || row?.contact_id || row?.visitor_id || row?.from_phone_number || row?.from_email || ''
            }
            variant={orgIcon ? 'image' : 'beam'}
            colors={colors}
          />
        </div>
        {organization && <div className={classes.chatBubble}>{row.message}</div>}
      </div>
      <span className={organization ? classes.timestampRight : classes.timestampLeft}>
        {prettyDateTime(row.created_at)}{' '}
      </span>
    </div>
  );
};

export default function Convo({
  colors,
  initialMessage,
  organizationID,
  visitorID,
  contactID,
  convo,
  setConvo,
  setInitialMessage,
}) {
  const { state: {sharedPatientData} } = useContext(SearchContext);
  const theme = useTheme();
  const matchSM = useMediaQuery(theme.breakpoints.only('xs'));
  
  const {token, userID} = useCurrentUser();
  const chatRef = useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [newMessage, setNewMessage] = useState(initialMessage);
  const [orgIcon, setOrgIcon] = useState('');
  const [posting, setPosting] = useState(false);

  useEffect(() => {
    // auto scroll down on new messages
    chatRef.current.scrollTop = chatRef.current.scrollHeight;
  }, [convo]);
  const handleSendMessage = useCallback(() => {
    enqueueSnackbar('Currently Diagnosing, this may take up to 1 minute.', { variant: 'success' });
    setPosting(true);
      const object = {
        organizationId: organizationID,
        visitorId: visitorID,
        contactId: contactID,
        message: newMessage ?? 'Diagnose',
        patient: sharedPatientData,
      };
      postEmbeddableChat(object, token, userID).then(response => {
        if (response && response.Diagnosis && response.Diagnosis.length) {
          const diagnosisMessages = response.Diagnosis.map((diagnosis, index) => ({
            id: index + 2,
            ai_responded: true,
            message: diagnosis,
            created_at: new Date(),
          }));
          const newConvo = [
            { id: 1, ai_responded: false, message: newMessage ?? 'Diagnose', created_at: new Date() },
            ...diagnosisMessages,
          ]
          setConvo(newConvo);
          setNewMessage('');
          setPosting(false);
          //refetch();
        } else {
          setPosting(false);
          enqueueSnackbar('Something went wrong, try again.', { variant: 'error' });
        }
      });
  }, [newMessage, organizationID, contactID, sharedPatientData]);

  useEffect(() => {
    // send the first message for them
    if (initialMessage && initialMessage === newMessage) {
      handleSendMessage();
      setInitialMessage('');
    }
  }, [initialMessage, newMessage, setInitialMessage, handleSendMessage]);

  useEffect(() => {
    if (organizationID && 1==2) {
      const image = new Image();
      image.src = `https://sendboth.s3.amazonaws.com/organizations/${organizationID}_icon.png?r=${Math.random()}`;
      image.onload = () => {
        setOrgIcon(image.src);
      };
      image.onerror = () => {
        setOrgIcon('');
      };
    }
  }, [organizationID]);

  useEffect(() => {
    const callback = event => {
      if (newMessage) {
        if (event.code === 'Enter') {
          event.preventDefault();
          handleSendMessage();
        }
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [newMessage, handleSendMessage]);

  return (
    <Box style={{ padding: matchSM ? 0 : 20 }}>
      {!convo && (
        <Typography color={'textSecondary'} style={{ textAlign: 'center', marginTop: 200, marginBottom: 200 }}>
          Loading Conversation...
        </Typography>
      )}
      <div className={classes.convoContainer} ref={chatRef}>
        <>
          {Boolean(convo) &&
            convo.map(row => {
              return (
                <React.Fragment key={row.id}>
                  {row.ai_responded || row.responded_by ? (
                    <MessageBubble organization orgIcon={orgIcon} row={row} colors={colors} />
                  ) : (
                    <MessageBubble row={row} colors={colors} />
                  )}
                </React.Fragment>
              );
            })}
        </>
      </div>

      <DialogActions>
        <TextField
          autoFocus={true}
          value={newMessage}
          onChange={e => {
            setNewMessage(e.target.value);
          }}
          variant={'outlined'}
          fullWidth={true}
          multiline
          label={
            <>
              <ChatBubble style={{ marginBottom: -6 }} /> New Message
            </>
          }
          placeholder={'I need assistance with...'}
          InputProps={{
            endAdornment: (<>
                 { posting ? <IconButton onClick={handleSendMessage} disabled={!newMessage || posting}>
                      {posting ? <CircularProgress size={'2rem'} /> : <Send />}
                    </IconButton> :
                    <></>}
               </>
            ),
          }}
        />
      </DialogActions>
      <Button variant="contained" style={{width:160,margin:'auto', display:'block'}} color="primary" onClick={handleSendMessage} disabled={posting}>
        Diagnose
      </Button>
    </Box>
  );
}
